import env from "@/utils/env";
import splitbee from "@splitbee/web";
import { useEffect } from "react";

export function useSplitbee() {
  useEffect(() => {
    if (env.isProd()) {
      splitbee.init({
        token: process.env.NEXT_PUBLIC_SPLITBEE_TOKEN,
        scriptUrl: "/bee.js",
        apiUrl: "/_hive",
      });
    }
  }, []);
}

export default splitbee;
