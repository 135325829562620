import env from "@/utils/env";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";

const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID!;
const HOTJAR_VERSION = process.env.NEXT_PUBLIC_HOTJAR_VERSION!;

export function useHotjar() {
  useEffect(() => {
    if (env.isProd()) {
      hotjar.initialize(parseInt(HOTJAR_ID, 10), parseInt(HOTJAR_VERSION, 10));
    }
  }, []);
}

export default hotjar;
