import React, { useEffect, useState } from "react";
import { useSession } from "next-auth/react";

import Loading from "@/components/app/Loading";
import { useRouter } from "next/router";

const MINIMUM_LOADING_TIME = 1000;

export default function Auth({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const { data: session, status } = useSession();
  const [ready, setReady] = useState(false);
  const isUser = !!session?.user;

  useEffect(() => {
    // Minimum loading time to prevent a perceived flash of the loader
    setTimeout(() => {
      setReady(true);
    }, MINIMUM_LOADING_TIME);

    // Do nothing while loading
    if (status === "loading") return;

    // If it's done loading and there's no user, redirect to the login page
    if (!isUser) router.push("/login");
  }, [isUser, router, status]);

  if (isUser && ready) {
    return <div className="animate__animated animate__fast animate__fadeIn">{children}</div>;
  }

  return <Loading />;
}
