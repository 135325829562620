import Image from "next/image";

export default function Loading() {
  return (
    <div className="min-h-screen px-4 py-16 bg-white sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="animate__animated animate__fast animate__fadeInUp">
          <Image
            className="w-auto h-28"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-300.svg"
            alt="Workflow"
            width={112}
            height={112}
          />
        </main>
      </div>
    </div>
  );
}
