// NODE_ENV is used for build purposes, but for application runtime purposes
// we're using APP_ENV to identify the current runtime environment.
const appEnv = process.env.APP_ENV!;
const publicAppEnv = process.env.NEXT_PUBLIC_APP_ENV!;

const matchesProd = (input: string) => /pro?d/.test(input);
const matchesDev = (input: string) => /dev/.test(input);
const matchesTest = (input: string) => /test/.test(input);

const isProd = (): boolean => {
  return matchesProd(appEnv) || matchesProd(publicAppEnv);
};

const isDev = (): boolean => {
  return matchesDev(appEnv) || matchesDev(publicAppEnv);
};

const isTest = (): boolean => {
  return matchesTest(appEnv) || matchesTest(publicAppEnv);
};

const hostname = process.env.NEXT_PUBLIC_HOSTNAME;
const baseUrl = (isDev() ? "http" : "https") + `://${hostname}`;

const env = {
  isProd,
  isDev,
  isTest,
  baseUrl,
};

export default env;
