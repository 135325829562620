import "tailwindcss/tailwind.css";
import "animate.css";
import "@/styles/global.css";

import type { AppRouter } from "@/backend/router";
import Auth from "@/components/app/Auth";
import type { CustomAppProps } from "@/utils/types";
import { ModalContextProvider } from "@/context/Modal";
import React from "react";
import { SessionProvider } from "next-auth/react";
import env from "@/utils/env";
import { useHotjar } from "@/utils/hotjar";
import { useSplitbee } from "@/utils/splitbee";
import { withTRPC } from "@trpc/next";

function MyApp({ Component, pageProps: { session, ...pageProps } }: CustomAppProps) {
  useHotjar();
  useSplitbee();

  return (
    <SessionProvider session={session}>
      <ModalContextProvider>
        {Component.auth ? (
          <Auth>
            <Component {...pageProps} />
          </Auth>
        ) : (
          <Component {...pageProps} />
        )}
      </ModalContextProvider>
    </SessionProvider>
  );
}

export default withTRPC<AppRouter>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */

    const url = `${env.baseUrl}/api/trpc`;

    return {
      url,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: false,
})(MyApp);
