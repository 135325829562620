import React, { createContext, useContext, useState } from "react";

export const ModalContext = createContext({});

type IModalContextProvider = {
  children: React.ReactNode;
};

type IModal = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

type IUseModal = {
  paymentModal: IModal;
  ethModal: IModal;
};

export const ModalContextProvider = (props: IModalContextProvider) => {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [ethModalOpen, setEthModalOpen] = useState(false);

  const value = {
    paymentModal: {
      isOpen: paymentModalOpen,
      open: () => setPaymentModalOpen(true),
      close: () => setPaymentModalOpen(false),
    },
    ethModal: {
      isOpen: ethModalOpen,
      open: () => setEthModalOpen(true),
      close: () => setEthModalOpen(false),
    },
  };

  return <ModalContext.Provider value={value}>{props.children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext) as IUseModal;
